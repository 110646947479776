//jsxhook

import { NextPage } from "next"
import { Stores } from "../../cart/Stores"
import { CatchAllProps } from "../../routing/PageProps"
import { getServerSideIndexPropsWithKnownStore } from "../../utils/getServerSideIndexPropsWithKnownStore"
import ParamsPage from "../[...params]"

export const Homepage: NextPage<CatchAllProps> = ParamsPage
export const getServerSideProps = getServerSideIndexPropsWithKnownStore(
  Stores.DK
)

export default Homepage
